import type { Team, Subscription } from "~/types";

export const useUserStore = defineStore("userStore", () => {
  let hasLoadedTeams = false;
  let hasLoadedSubscription = false;
  const { user, clear } = useUserSession();
  const anonId = ref<string | null>();
  const teams = ref<Array<Team & { role: string | null }>>([]);
  const subscription = ref<Subscription | null>(null);

  const fetchTeams = async (force = false) => {
    if (!force && hasLoadedTeams) return;

    const { data } = await useFetch("/api/teams");

    if (data.value) {
      hasLoadedTeams = true;

      teams.value = data.value;
    }
  };

  const fetchSubscription = async (force = false) => {
    const { data } = await useFetch("/api/subscriptions");

    if (data.value) {
      hasLoadedSubscription = true;

      subscription.value = data.value;
    }
  };

  function $reset() {
    clear();
    teams.value = [];
    subscription.value = null;
    useCookie("anon_id").value = null;
  }

  return {
    anonId,
    user,
    teams,
    subscription,
    fetchTeams,
    fetchSubscription,
    $reset,
  };
});
